import React, { lazy, Suspense, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { sfPosthog } from '@sigfig/digital-wealth-core';

import { PageLoading } from '../components/Loading';
import { Logout } from '../components/Logout';
import { Qa } from '../components/Qa';
import { ReturnToBns } from '../components/ReturnToBns';
import { isProdOrStg } from '../config';
import { FourOhFour } from '../containers/FourOhFour';
import { GetInTouch } from '../containers/GetInTouch';
import { SomethingWentWrong } from '../containers/SomethingWentWrong';

import { AppLayout } from './AppLayout';
import { AuthedApp } from './AuthedApp';
import { AuthWrapper } from './AuthWrapper';
import { Entry } from './Entry';
import { PageRoute, routes } from './routes';

const AccountAlreadyInProgress = lazy(() => import('../containers/AccountAlreadyInProgress'));
const AccountDetails = lazy(() => import('../containers/AccountDetails'));
const Dashboard = lazy(() => import('../containers/Dashboard'));
const EligibilityCheck = lazy(() => import('../containers/EligibilityCheck'));
const EntryRedirect = lazy(() => import('../components/EntryRedirect'));
const GoalsCreate = lazy(() => import('../containers/Goals/Create'));
const GoalsEdit = lazy(() => import('../containers/Goals/Edit'));
const Onboarding = lazy(() => import('../containers/Onboarding'));
const RetakeWrapper = lazy(() => import('./RetakeWrapper'));

function App(): unknown {
  const [selectedAccountNumber, setSelectedAccountNumber] = useState('');
  const [certificateNumber, setCertificateNumber] = useState(''); // for GIC
  sfPosthog.useCaptureSpaPageViews(useLocation());

  return (
    <Suspense fallback={<PageLoading />}>
      <AuthWrapper>
        <Routes>
          {!isProdOrStg() && <Route element={<Qa />} path="/qa" />}
          <Route element={<Logout />} path={PageRoute.Logout} />
          <Route element={<ReturnToBns />} path={PageRoute.ReturnToBns} />
          <Route element={<FourOhFour />} path="*" />
          <Route element={<Entry />} path={routes.redirect()} />
          <Route element={<AuthedApp />}>
            <Route
              element={
                <EntryRedirect
                  onCertificateNumberChange={setCertificateNumber}
                  onSelectedAccountChange={setSelectedAccountNumber}
                />
              }
              path={PageRoute.AuthedRedirect}
            />
            <Route element={<AppLayout />}>
              <Route element={<AccountAlreadyInProgress />} path={PageRoute.AccountAlreadyInProgress} />
              <Route
                element={
                  <AccountDetails
                    onCertificateNumberChange={setCertificateNumber}
                    onSelectedAccountChange={setSelectedAccountNumber}
                  />
                }
                path={PageRoute.AccountDetails}
              />
              <Route
                element={<Dashboard setSelectedAccountNumber={setSelectedAccountNumber} />}
                path={PageRoute.Dashboard}
              />
              <Route
                element={
                  <EligibilityCheck
                    certificateNumber={certificateNumber}
                    onSelectedAccountChange={setSelectedAccountNumber}
                  />
                }
                path={PageRoute.EligibilityCheck}
              />
              <Route
                element={
                  <EligibilityCheck
                    certificateNumber={certificateNumber}
                    onSelectedAccountChange={setSelectedAccountNumber}
                  />
                }
                path={PageRoute.EligibilityCheckForEntry}
              />
              <Route element={<Onboarding />} path={PageRoute.Onboarding} />
              <Route element={<Onboarding />} path={PageRoute.OnboardingStep} />
              <Route element={<GetInTouch />} path={PageRoute.GetInTouch} />
              <Route element={<GetInTouch isOffRamp />} path={PageRoute.OffRamp} />
              <Route
                element={<Dashboard setSelectedAccountNumber={setSelectedAccountNumber} />}
                path={PageRoute.Goals}
              />
              <Route element={<GoalsCreate />} path={PageRoute.GoalsCreate} />
              <Route element={<GoalsEdit />} path={PageRoute.GoalsEdit} />
              <Route element={<GoalsEdit showV1 />} path={PageRoute.GoalsEditV1} />
              <Route element={<RetakeWrapper accountNumber={selectedAccountNumber} />} path={PageRoute.Retake} />
              <Route element={<SomethingWentWrong />} path={PageRoute.SomethingWentWrong} />
            </Route>
          </Route>
        </Routes>
      </AuthWrapper>
    </Suspense>
  );
}

export default App;

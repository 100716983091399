import { differenceInYears, parseISO } from 'date-fns';
import * as Cookies from 'js-cookie';
import { generatePath } from 'react-router-dom';

import { CustomActionArgs, FinancialAccountType, getDeepLink, QueryParam } from '@sigfig/digital-wealth-core';

import config, { getPreferredEnvironmentFallback, isProdOrStg } from '../config';
import { EntryApplicationAccountAction } from '../config/accountActionUrls';
import { EntryContext } from '../contexts/Entry/types';

import { RetakeContext } from './RetakeWrapper/util';
import { AccountActionSource, PageRoute } from './routes';

// Maintenance CTAs are the ones that does not involve PortfolioSelection in the flow.
export const MAINTENANCE_CTAS: RetakeContext[] = ['other'];
export const DEFAULT_DIGITAL_FINANCIAL_ACCOUNTS = [
  FinancialAccountType.NRSPR,
  FinancialAccountType.RRS,
  FinancialAccountType.TFS,
];

export type DeepLinkParams = {
  account_id?: QueryParam;
  bns_summary_url?: QueryParam;
  channel?: QueryParam;
  intent?: QueryParam;
  ipob_app_id?: QueryParam;
  language?: QueryParam;
  locale?: QueryParam;
  plan_type?: QueryParam;
  portfolio_id?: QueryParam;
  preferred_environment?: QueryParam;
  sigfig_return_url?: QueryParam;
};

export const removeAuth0Cookies = (): void => {
  Object.keys(Cookies.get()).forEach(cookieName => {
    if (cookieName.includes('auth0')) {
      Cookies.remove(cookieName);
    }
  });
};

export const concludeOnboardingCallback = (
  entryContext?: EntryContext,
): ((args: Pick<CustomActionArgs, 'managedProductId'>) => void) => ({ managedProductId }) => {
  if (!managedProductId) {
    console.warn('managedProductId is required for portfolio_id in the Onboarding exit point');
  }
  if (!entryContext) {
    console.warn('entryContext is required for the Onboarding exit point');
  }

  deepLinkToExitPoint({
    action: 'continueOnboardingOrPurchase',
    entryContext,
    managedProductId,
  });
};

/**
 * Non-Migrated account:
 *  For any CTA,
 *    If questionnaires is empty (indicating Account Relationship update)
 *      Return false
 *    Else If the lastRtqDate < 1 yr, show modal
 *      Return true
 *    Else
 *      Return false
 * Migrated account:
 *    Maintenance CTA (Add Funds, Other, Withdraw)
 *      (Same as non-migrated above)
 *      If completedOn < 1 yr
 *        Return true
 *      Else
 *        Return false
 *    Non-Maintenance CTA (RTQ or Switch Product)
 *      Return false
 */
export const isRtqValid = ({
  completedOn,
  lastRtqDate,
  isMigrated,
  retakeContext,
}: {
  completedOn: string;
  isMigrated: boolean;
  lastRtqDate: string;
  retakeContext: RetakeContext;
}): boolean => {
  if (!completedOn) {
    // When an account is moved from one user to another via Relationship Update API,
    // there won't be any questionnaire for the new user, so RTQ must be taken
    return false;
  }
  const isMaintenanceCta = MAINTENANCE_CTAS.includes(retakeContext);
  if ((isMigrated && isMaintenanceCta) || !isMigrated) {
    const lastRtq = isMigrated ? completedOn : lastRtqDate;
    return !!lastRtq && differenceInYears(Date.now(), parseISO(lastRtq)) < 1;
  }

  // Migrated accounts requires to retake RTQ for non-maintenance Ctas
  return false;
};

export type EntryApplicationAccountActionForDeepLink = Extract<
  EntryApplicationAccountAction,
  'addFunds' | 'continueOnboardingOrPurchase' | 'manageTrustedContactPerson' | 'withdrawFunds'
>;
export type DeepLinkActionOrRetakeContext =
  | { action: Exclude<EntryApplicationAccountActionForDeepLink, 'manageTrustedContactPerson'>; retakeContext?: never }
  | { accountNumber: string; action: 'manageTrustedContactPerson'; entryContext: EntryContext; retakeContext?: never }
  | { action?: never; retakeContext: RetakeContext };
type DeepLinkArgs = {
  accountNumber?: string;
  accountType?: FinancialAccountType | null;
  entryContext?: EntryContext;
  managedProductId?: string;
  source?: AccountActionSource;
} & DeepLinkActionOrRetakeContext;
export const getParamsForBnsDeepLink = ({
  accountNumber,
  accountType,
  action,
  retakeContext,
  entryContext,
  managedProductId,
  source,
}: DeepLinkArgs): DeepLinkParams => {
  const preferredEnvironment = getPreferredEnvironment(entryContext);
  const defaultParams = {
    bns_summary_url: { value: entryContext?.bnsSummaryUrl ?? '' },
    ...(preferredEnvironment ? { preferred_environment: { value: preferredEnvironment } } : undefined),
  };

  const defaultOnboardingFlowParams: DeepLinkParams = {
    ...defaultParams,
    ipob_app_id: { value: entryContext?.ipobAppId ?? '' },
    language: { value: entryContext?.locale ?? '' },
    portfolio_id: { encrypt: true, value: managedProductId ?? '' },
  };

  switch (action) {
    case 'continueOnboardingOrPurchase':
      return {
        ...defaultOnboardingFlowParams,
        channel: { value: entryContext?.channel ?? '' },
      };
    case 'manageTrustedContactPerson':
      return {
        ...defaultParams,
        account_id: { value: accountNumber, encrypt: true },
        intent: { value: entryContext.intent },
        locale: { value: entryContext.locale ?? '' },
        sigfig_return_url: { value: window.location.href },
      };
  }

  switch (retakeContext) {
    case 'purchase-no-change':
    case 'purchase':
    case 'renew-gic':
    case 'renew-gic-no-change': {
      return {
        ...defaultOnboardingFlowParams,
        sigfig_return_url: { value: window.location.href },
      };
    }
    default: {
      // Default back to dashboard if source is unavailable or invalid managedProductId.
      const url = new URL(
        source === 'accountDetails' && managedProductId
          ? generatePath(PageRoute.AccountDetails, { managedProductId })
          : generatePath(PageRoute.Dashboard),
        window.location.origin,
      ).href;

      return {
        ...defaultParams,
        account_id: { value: accountNumber ?? '', encrypt: true },
        plan_type: { value: entryContext?.planType ?? accountType ?? '' },
        sigfig_return_url: { value: url },
      };
    }
  }
};

/**
 * preferred_environment param is expected in all non-prod env deeplinks.
 */
export const getPreferredEnvironment = (entryContext?: EntryContext) =>
  // TODO PDZ-882: Remove getPreferredEnvironmentFallback for hardcoded fallback
  isProdOrStg() ? undefined : entryContext?.preferredEnvironment || getPreferredEnvironmentFallback();

export const deepLinkToExitPoint = (args: DeepLinkArgs) => {
  const baseUrl = getBaseUrl(args);
  const params = getParamsForBnsDeepLink(args);

  const exitPoint = getDeepLink(baseUrl, params, { key: config.deepLink.key });
  window.location.assign(exitPoint);
};

const getBaseUrl = ({ action, retakeContext }: DeepLinkActionOrRetakeContext): string => {
  switch (action) {
    case 'addFunds':
      return config.accountActionUrls.addFunds;
    case 'continueOnboardingOrPurchase':
      return config.accountActionUrls.continueOnboardingOrPurchase;
    case 'manageTrustedContactPerson':
      return config.accountActionUrls.manageTrustedContactPerson;
    case 'withdrawFunds':
      return config.accountActionUrls.withdrawFunds;
  }

  return retakeContext === 'edit-gic-maturity-instructions'
    ? config.accountActionUrls.editGicMaturityInstructions
    : config.accountActionUrls.continueOnboardingOrPurchase;
};

import { FinancialAccountType } from '../../__generated__/symphonyTypes.v2';

export const channels = [
  'AB',
  'AC',
  'BK',
  'BP',
  'BR',
  'CC',
  'CS',
  'DE',
  'DN',
  'FA',
  'FF',
  'IV',
  'M',
  'MA',
  'MB',
  'ME',
  'MN',
  'MM',
  'MO',
  'MW',
  'M1',
  'M2',
  'M3',
  'ND',
  'NE',
  'NN',
  'N1',
  'N2',
  'PC',
  'PK',
  'SC',
  'SP',
  'S1',
  'S2',
  'S7',
  'TC',
  'TS',
  'WL',
  'WM',
  'XE',
] as const;

export const connections = ['oidc', 'saml'] as const;

export const entrySearchParamKeys = [
  'account_id',
  'bns_summary_url',
  'certificate_number',
  'channel',
  'conn',
  'intent',
  'ipob_app_id',
  'locale',
  'plan_type',
  'preferred_environment',
  'security_id',
  'session_timeout',
  'source',
  'start_point',
] as const;

export const intents = [
  'accountdetails',
  'addfunds',
  'dashboard',
  'onboardnewaccount',
  'purchase',
  'renewgic',
  'retakertq',
  'reviewselectedproducts',
  'withdrawfunds',
] as const;

export const locales = ['en', 'fr'] as const;

export const planTypes = [FinancialAccountType.NRSPR, FinancialAccountType.RRS, FinancialAccountType.TFS] as const;

export const sources = ['native', 'web'] as const;

export const startPoints = ['context', 'goals', 'madlibs'] as const;

export type Channel = typeof channels[number];

export type Connection = typeof connections[number];

export type EntrySearchParamKey = typeof entrySearchParamKeys[number];

export type Intent = typeof intents[number];

export type Locale = typeof locales[number];

export type PlanType = typeof planTypes[number];

export type Source = typeof sources[number];

export type StartPoint = typeof startPoints[number];

export type EntryEnum = Channel | Connection | Intent | Locale | PlanType | Source | StartPoint;

export interface Encrypted {
  encrypted: string;
}

export interface EntryContext {
  accountId?: string; // ie: managedProductId
  bnsSummaryUrl: string;
  certificateNumber?: Encrypted;
  channel: Channel; // ie: deliveryChannel
  connection: Connection;
  intent: Intent;
  ipobAppId?: string;
  locale?: Locale;
  planType?: PlanType;
  preferredEnvironment?: string;
  securityId?: string;
  sessionTimeout: number;
  source: Source;
  startPoint?: StartPoint;
}

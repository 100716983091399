import { ConfigValues } from '.';

export type EntryApplicationAccountAction =
  | 'addFunds'
  | 'continueOnboardingOrPurchase'
  | 'editGicMaturityInstructions'
  | 'manageTrustedContactPerson'
  | 'viewStatements'
  | 'viewStatementsNova'
  | 'viewTaxDocuments'
  | 'viewPac'
  | 'viewTransactionHistory'
  | 'withdrawFunds';
export const accountActionUrlConfigs: Record<EntryApplicationAccountAction, ConfigValues> = {
  addFunds: {
    sqa1: 'https://secure-u.scointnet.net/investmm/deposit',
    sqa2: 'https://secure-u.scointnet.net/investmm/deposit',
    sqa3: 'https://secure-u.scointnet.net/investmm/deposit',
    sqa4: 'https://secure-u.scointnet.net/investmm/deposit',
    sqa5: 'https://secure-u.scointnet.net/investmm/deposit',
    sqa6: 'https://secure-u.scointnet.net/investmm/deposit',
    sqa7: 'https://secure-u.scointnet.net/investmm/deposit',
    sqa8: 'https://secure-u.scointnet.net/investmm/deposit',
    stg: 'https://orion-investmm-nft.apps.stg.azr-cc-pcf.cloud.bns/investmm/deposit',
    prod: 'https://secure.scotiabank.com/investmm/deposit',
    _: 'https://dummy-addFunds/investmm/deposit',
  },
  continueOnboardingOrPurchase: {
    sqa1: 'https://ipo-ma-ist.apps.stg.azr-use2-pcf.cloud.bns/investment/resume-application',
    sqa2: 'https://ipo-ma-uat.apps.stg.azr-use2-pcf.cloud.bns/investment/resume-application',
    sqa3: 'https://ipo-ma-ist.apps.stg.azr-cc-pcf.cloud.bns/investment/resume-application',
    sqa4: 'https://ipo-ma-uat.apps.stg.azr-use2-pcf.cloud.bns/investment/resume-application',
    sqa5: 'https://ipo-ma-ist.apps.stg.azr-use2-pcf.cloud.bns/investment/resume-application',
    sqa6: 'https://hello-u.scointnet.net/investment/resume-application',
    sqa7: 'https://ipo-ma-ist.apps.stg.azr-cc-pcf.cloud.bns/investment/resume-application',
    sqa8: 'https://ipo-ma-uat.apps.stg.azr-use2-pcf.cloud.bns/investment/resume-application',
    stg: 'https://ipo-ma-nft.apps.stg.azr-cc-pcf.cloud.bns/investment/resume-application',
    prod: 'https://hello.scotiabank.com/investment/resume-application',
    _: 'https://dummy-continueOnboardingOrPurchase/investment/resume-application',
  },
  editGicMaturityInstructions: {
    prod: 'https://secure.scotiabank.com/investmm/gic',
    sqa1: 'https://secure-u.scointnet.net/investmm/gic',
    sqa2: 'https://secure-u.scointnet.net/investmm/gic',
    sqa3: 'https://secure-u.scointnet.net/investmm/gic',
    sqa4: 'https://secure-u.scointnet.net/investmm/gic',
    sqa5: 'https://secure-u.scointnet.net/investmm/gic',
    sqa6: 'https://secure-u.scointnet.net/investmm/gic',
    sqa7: 'https://secure-u.scointnet.net/investmm/gic',
    sqa8: 'https://secure-u.scointnet.net/investmm/gic',
    _: 'https://dummy-editGicMaturityInstructions/investmm/gic',
  },
  manageTrustedContactPerson: {
    sqa1: 'https://secure-u.scointnet.net/investmm/tcp',
    sqa2: 'https://secure-u.scointnet.net/investmm/tcp',
    sqa3: 'https://secure-u.scointnet.net/investmm/tcp',
    sqa4: 'https://secure-u.scointnet.net/investmm/tcp',
    sqa5: 'https://secure-u.scointnet.net/investmm/tcp',
    sqa6: 'https://secure-u.scointnet.net/investmm/tcp',
    sqa7: 'https://secure-u.scointnet.net/investmm/tcp',
    sqa8: 'https://secure-u.scointnet.net/investmm/tcp',
    stg: 'https://orion-investmm-nft.apps.stg.azr-cc-pcf.cloud.bns/investmm/tcp',
    prod: 'https://secure.scotiabank.com/investmm/tcp',
    _: 'https://dummy-manageTrustedContactPerson/investmm/tcp',
  },
  viewStatements: {
    sqa1: 'https://ue43-black-uat-ui.sol.bns/online/views/edoc/ppsEStatements.bns?rssig=sigfig',
    sqa2: 'https://ue43-black-uat-ui.sol.bns/online/views/edoc/ppsEStatements.bns?rssig=sigfig',
    sqa3: 'https://ue43-black-uat-ui.sol.bns/online/views/edoc/ppsEStatements.bns?rssig=sigfig',
    sqa4: 'https://ue43-black-uat-ui.sol.bns/online/views/edoc/ppsEStatements.bns?rssig=sigfig',
    sqa5: 'https://ue43-black-uat-ui.sol.bns/online/views/edoc/ppsEStatements.bns?rssig=sigfig',
    sqa6: 'https://ue43-green-uat-ui.sol.bns/online/views/edoc/ppsEStatements.bns?rssig=sigfig',
    sqa7: 'https://ue43-black-uat-ui.sol.bns/online/views/edoc/ppsEStatements.bns?rssig=sigfig',
    sqa8: 'https://uc13-red-uat-ui.sol.bns/online/views/edoc/ppsEStatements.bns?rssig=sigfig',
    stg: 'https://www.uat.scotiaonline.scotiabank.com/online/views/edoc/ppsEStatements.bns?rssig=sigfig',
    prod: 'https://www.scotiaonline.scotiabank.com/online/views/edoc/ppsEStatements.bns?rssig=sigfig',
    _: 'https://dummy-viewStatements/online/views/edoc/ppsEStatements.bns?rssig=sigfig',
  },
  viewStatementsNova: { _: 'scotiabank://nova/accounts/statements/investments' },
  viewTaxDocuments: {
    sqa1: 'https://ue43-black-uat-ui.sol.bns/online/views/commcentre/taxDocument.bns?rssig=sigfig',
    sqa2: 'https://ue43-black-uat-ui.sol.bns/online/views/commcentre/taxDocument.bns?rssig=sigfig',
    sqa3: 'https://ue43-black-uat-ui.sol.bns/online/views/commcentre/taxDocument.bns?rssig=sigfig',
    sqa4: 'https://ue43-black-uat-ui.sol.bns/online/views/commcentre/taxDocument.bns?rssig=sigfig',
    sqa5: 'https://ue43-black-uat-ui.sol.bns/online/views/commcentre/taxDocument.bns?rssig=sigfig',
    sqa6: 'https://ue43-green-uat-ui.sol.bns/online/views/commcentre/taxDocument.bns?rssig=sigfig',
    sqa7: 'https://ue43-black-uat-ui.sol.bns/online/views/commcentre/taxDocument.bns?rssig=sigfig',
    sqa8: 'https://uc13-red-uat-ui.sol.bns/online/views/commcentre/taxDocument.bns?rssig=sigfig',
    stg: 'https://www.uat.scotiaonline.scotiabank.com/online/views/commcentre/taxDocument.bns?rssig=sigfig',
    prod: 'https://www.scotiaonline.scotiabank.com/online/views/commcentre/taxDocument.bns?rssig=sigfig',
    _: 'https://dummy-viewTaxDocuments/online/views/commcentre/taxDocument.bns?rssig=sigfig',
  },
  viewPac: {
    sqa1:
      'https://ue43-black-uat-ui.sol.bns/online/views/prodservapply/investmentandwealth/retail/investmentplans/preAuthorizedContributions.bns?rssig=sigfig',
    sqa2:
      'https://ue43-black-uat-ui.sol.bns/online/views/prodservapply/investmentandwealth/retail/investmentplans/preAuthorizedContributions.bns?rssig=sigfig',
    sqa3:
      'https://ue43-black-uat-ui.sol.bns/online/views/prodservapply/investmentandwealth/retail/investmentplans/preAuthorizedContributions.bns?rssig=sigfig',
    sqa4:
      'https://ue43-black-uat-ui.sol.bns/online/views/prodservapply/investmentandwealth/retail/investmentplans/preAuthorizedContributions.bns?rssig=sigfig',
    sqa5:
      'https://ue43-black-uat-ui.sol.bns/online/views/prodservapply/investmentandwealth/retail/investmentplans/preAuthorizedContributions.bns?rssig=sigfig',
    sqa6:
      'https://ue43-green-uat-ui.sol.bns/online/views/prodservapply/investmentandwealth/retail/investmentplans/preAuthorizedContributions.bns?rssig=sigfig',
    sqa7:
      'https://ue43-black-uat-ui.sol.bns/online/views/prodservapply/investmentandwealth/retail/investmentplans/preAuthorizedContributions.bns?rssig=sigfig',
    sqa8:
      'https://uc13-red-uat-ui.sol.bns/online/views/prodservapply/investmentandwealth/retail/investmentplans/preAuthorizedContributions.bns?rssig=sigfig',
    stg:
      'https://www.uat.scotiaonline.scotiabank.com/online/views/prodservapply/investmentandwealth/retail/investmentplans/preAuthorizedContributions.bns?rssig=sigfig',
    prod:
      'https://www.scotiaonline.scotiabank.com/online/views/prodservapply/investmentandwealth/retail/investmentplans/preAuthorizedContributions.bns?rssig=sigfig',
    _:
      'https://dummy-viewPac/online/views/prodservapply/investmentandwealth/retail/investmentplans/preAuthorizedContributions.bns?rssig=sigfig',
  },
  viewTransactionHistory: {
    sqa1: 'https://ue43-black-uat-ui.sol.bns',
    sqa2: 'https://ue43-black-uat-ui.sol.bns',
    sqa3: 'https://ue43-black-uat-ui.sol.bns',
    sqa4: 'https://ue43-black-uat-ui.sol.bns',
    sqa5: 'https://ue43-black-uat-ui.sol.bns',
    sqa6: 'https://ue43-green-uat-ui.sol.bns',
    sqa7: 'https://ue43-black-uat-ui.sol.bns',
    sqa8: 'https://uc13-red-uat-ui.sol.bns',
    stg: 'https://www.uat.scotiaonline.scotiabank.com',
    prod: 'https://www.scotiaonline.scotiabank.com',
    _: 'https://dummy-viewTransactionHistory',
  },
  withdrawFunds: {
    sqa1: 'https://secure-u.scointnet.net/investmm/withdraw',
    sqa2: 'https://secure-u.scointnet.net/investmm/withdraw',
    sqa3: 'https://secure-u.scointnet.net/investmm/withdraw',
    sqa4: 'https://secure-u.scointnet.net/investmm/withdraw',
    sqa5: 'https://secure-u.scointnet.net/investmm/withdraw',
    sqa6: 'https://secure-u.scointnet.net/investmm/withdraw',
    sqa7: 'https://secure-u.scointnet.net/investmm/withdraw',
    sqa8: 'https://secure-u.scointnet.net/investmm/withdraw',
    stg: 'https://orion-investmm-nft.apps.stg.azr-cc-pcf.cloud.bns/investmm/withdraw',
    prod: 'https://secure.scotiabank.com/investmm/withdraw',
    _: 'https://dummy-withdrawFunds/investmm/withdraw',
  },
};

import React, { FC } from 'react';

import {
  Link,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  useModalState,
} from '@sigfig/digital-wealth-core';

import { isProdOrStg } from '../../config';
import { useApp } from '../../contexts/App';
import { useEntryContext } from '../../contexts/Entry';

/**
 * For non-production environment rendering only!
 * Currently only the entry context details are shown in the modal.
 * The modal can expose whatever information useful for QA.
 */
export const QaDetails: FC = () => {
  const { contentOptions } = useApp();
  const [entryContext] = useEntryContext();
  const qaModal = useModalState();

  if (isProdOrStg() || !entryContext) {
    return null;
  }

  return (
    <>
      <Link onClick={qaModal.openModal} sx={{ mt: 3 }}>
        QA Details
      </Link>
      <Modal
        content={
          <Table size="small">
            <TableBody>
              {Object.entries(entryContext).map(
                ([key, entry]) =>
                  entry && (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{typeof entry === 'string' ? entry : JSON.stringify(entry)}</TableCell>
                    </TableRow>
                  ),
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} sx={{ border: 'none' }}>
                  QA Details view for non-production environment only.
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        }
        contentOptions={contentOptions}
        onClose={qaModal.onClose}
        open={qaModal.open}
        title="QA Details"
      />
    </>
  );
};

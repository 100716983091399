import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import {
  GetSomethingWentWrongContent,
  GetSomethingWentWrongContentVariables,
} from './__generated__/getSomethingWentWrongContent.v2';

const query = loader('./getSomethingWentWrongContent.gql');

export const useSomethingWentWrongContent = (
  options?: QueryHookOptions<GetSomethingWentWrongContent, GetSomethingWentWrongContentVariables>,
): QueryResult<GetSomethingWentWrongContent, GetSomethingWentWrongContentVariables> => {
  return useContentstackQuery(query, options);
};

import {
  Channel,
  channels,
  Connection,
  connections,
  Encrypted,
  EntryContext,
  EntryEnum,
  EntrySearchParamKey,
  Intent,
  intents,
  Locale,
  locales,
  PlanType,
  planTypes,
  Source,
  sources,
  StartPoint,
  startPoints,
} from './types';

type Predicate<T> = (v: any) => v is T;

const is = <T extends EntryEnum>(validValues: readonly T[]) => (v: any): v is T => validValues.includes(v);
const isChannel = is<Channel>(channels);
const isConnection = is<Connection>(connections);
const isIntent = is<Intent>(intents);
const isLocale = is<Locale>(locales);
const isPlanType = is<PlanType>(planTypes);
const isSource = is<Source>(sources);
const isStartPoint = is<StartPoint>(startPoints);
const isString = (v: any): v is string => !!v && typeof v === 'string';
const isEncrypted = (v: any): v is Encrypted => !!v && !!v.encrypted;
const isNumber = (v: any): v is number => v !== null && v !== undefined && typeof v === 'number';
const isUndefined = (v: any): v is undefined => v === undefined;

const or = <A, B>(p1: Predicate<A>, p2: Predicate<B>) => (v: any): v is A | B => p1(v) || p2(v);

const toEncrypted = (v: any) =>
  v
    ? {
        encrypted: v,
      }
    : undefined;
const toNumber = (v: any) => +v;

export const parseEntryContext = (searchParams: URLSearchParams): EntryContext => {
  const get = <T>(key: EntrySearchParamKey, predicate: Predicate<T>, parse: (value: any) => T = v => v): T => {
    const v = parse(searchParams.get(key) ?? undefined);
    if (predicate(v)) {
      return v;
    }
    throw new Error(`Key '${key}' has invalid value '${v}'`);
  };

  return {
    accountId: get('account_id', or(isUndefined, isString)),
    bnsSummaryUrl: get('bns_summary_url', isString),
    certificateNumber: get('certificate_number', or(isUndefined, isEncrypted), v => toEncrypted(decodeURIComponent(v))),
    channel: get('channel', isChannel),
    connection: get('conn', isConnection),
    intent: get('intent', isIntent),
    ipobAppId: get('ipob_app_id', or(isUndefined, isString)),
    locale: get('locale', or(isUndefined, isLocale)),
    planType: get('plan_type', or(isUndefined, isPlanType)),
    preferredEnvironment: get('preferred_environment', or(isUndefined, isString)),
    securityId: get('security_id', or(isUndefined, isString)),
    sessionTimeout: get('session_timeout', isNumber, toNumber),
    source: get('source', isSource),
    startPoint: get('start_point', or(isUndefined, isStartPoint)),
  };
};

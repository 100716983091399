import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useSymphonyQuery } from '@sigfig/digital-wealth-core';

import {
  GetClientCurrentAccount,
  GetClientCurrentAccount_client_financialAccounts,
  GetClientCurrentAccountVariables,
} from './__generated__/getClientCurrentAccounts.v2';
import {
  GetClientDigitalWealthAccounts,
  GetClientDigitalWealthAccountsVariables,
} from './__generated__/getClientDigitalWealthAccounts.v2';
import { GetClientInfo, GetClientInfoVariables } from './__generated__/getClientInfo.v2';

const getClientInfo = loader('./getClientInfo.gql');
const getClientDigitalWealthAccounts = loader('./getClientDigitalWealthAccounts.gql');
const getClientCurrentAccount = loader('./getClientCurrentAccounts.gql');

export type CurrentFinancialAccount = GetClientCurrentAccount_client_financialAccounts;

export const useGetClientInfo = (
  options?: QueryHookOptions<GetClientInfo, GetClientInfoVariables>,
): QueryResult<GetClientInfo, GetClientInfoVariables> => {
  return useSymphonyQuery(getClientInfo, options);
};

export const useGetClientDigitalWealthAccounts = (
  options?: QueryHookOptions<GetClientDigitalWealthAccounts, GetClientDigitalWealthAccountsVariables>,
): QueryResult<GetClientDigitalWealthAccounts, GetClientDigitalWealthAccountsVariables> => {
  return useSymphonyQuery(getClientDigitalWealthAccounts, options);
};

export const useGetClientCurrentAccount = (
  options?: QueryHookOptions<GetClientCurrentAccount, GetClientCurrentAccountVariables>,
): QueryResult<GetClientCurrentAccount, GetClientCurrentAccountVariables> =>
  useSymphonyQuery(getClientCurrentAccount, options);

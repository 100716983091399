import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import config from '../config';
import { useEntryContext } from '../contexts/Entry';
import { EntryContext } from '../contexts/Entry/types';

const getAdditionalProps = (entryContext: EntryContext) => {
  if (entryContext.connection === 'saml') {
    return;
  }
  return { connection: 'bns-oidc' };
};

export const AuthWrapper: React.FC = ({ children }) => {
  const [entryContext] = useEntryContext();
  const navigate = useNavigate();

  if (!entryContext?.connection) {
    return <>{children}</>;
  }

  return (
    <Auth0Provider
      audience={`https://${config.auth0.audienceEnv}.sigfig.api`}
      clientId={config.auth0.clientId}
      domain={config.auth0.domain}
      onRedirectCallback={appState => {
        navigate((appState && appState.returnTo) || window.location.pathname);
      }}
      redirectUri={window.location.origin}
      {...getAdditionalProps(entryContext)}
    >
      {children}
    </Auth0Provider>
  );
};
